/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Pranav Tiwari",
  title: "Hi all, I'm Pranav",
  subTitle: emoji(
    "Data Scientist and Backend Developer 🚀: Weaving Web Backends and Machine Learning Marvels with the Power of Python, Django REST, TensorFlow, and Beyond! 🌐📱🧠🚀."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1V41jjGrvHyGmBTKqVeNE0CcIlhTTpOkv/view?usp=share_link", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Tiwari-Pranav",
  linkedin: "https://www.linkedin.com/in/sirpranavtiwari/",
  gmail: "pranavtiwari548@gmail.com",
  gitlab: "",
  facebook: "",
  medium: "https://medium.com/@pranav-tiwari",
  stackoverflow: "",
  twitter: "https://twitter.com/SirPranavTiwari",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY BACKEND DEVELOPER & A MACHINE LEARNING ENGINEER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Programming Language I am comfortable in : Java, Python"
    ),
    emoji("⚡ Machine Learning Technologies: Python, Pyspark, Pytorch, Tensorflow"),
    emoji(
      "⚡ Web Stack: HTML, CSS, JavaScript, Flask, Django, Django-REST, Docker, JSP"
    ),
    emoji(
      "⚡ Data Management: MySql, Mongo-DB, Snowflake"
    ),
    emoji("⚡ Content Management System: Drupal-9"),
    emoji(
      "⚡ Version Control: Github"
    ),
    emoji(
      "⚡ CI/CD: Github Actions"
      ),
      emoji("⚡ Documentation Languages: LaTeX"),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
      design:'#ed333b'
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
      design:'#3584e4'

    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
      design:'#f6d32d'
    },
    {
      skillName: "SQL-database",
      fontAwesomeClassname: "fas fa-database",
      design:'#62a0ea'
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python",
      design:'#f6d32d'
    },
    {
      skillName: "Flask",
      fontAwesomeClassname: "fas fa-pepper-hot",
      design:'#000000'
    },
    {
      skillName: "django-REST",
      fontAwesomeClassname: "fas fa-server",
      design:'#818589'
    },
    {
      skillName: "PyTorch",
      fontAwesomeClassname: "fas fa-fire",
      design:'#ff7800'
    },
    {
      skillName: "ScikitLearn",
      fontAwesomeClassname: "fas fa-feather",
      design:'#f4be0b'
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker",
      design:'#1c71d8'
    },
    {
      skillName: "Snowflake",
      fontAwesomeClassname: "far fa-snowflake",
      design:'#00e4ff'
    },
    {
      skillName: "NodeJs",
      fontAwesomeClassname: "fab fa-node",
      design:'#26a269'
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
      design:'#e01b24'
    },
    {
      skillName: "github",
      fontAwesomeClassname: "fab fa-github",
      design:'#000000'
    },
    {
      skillName: "drupal",
      fontAwesomeClassname: "fab fa-brands fa-drupal",
      design:'#1a5fb4'
    }
    // <font-awesome-icon :icon="['fal', 'snowflake']" />
    // <font-awesome-icon :icon="['fas', 'globe']" />
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    // {
    //   schoolName: "Indian Institute of Science Banagalore",
    //   logo: require("./assets/images/iisc.jpg"),
    //   subHeader: "M. Tech. in Robotics and Autonomous Systems",
    //   duration: "August 2024 - April 2026",
    //   // desc: "CGPA: ",
    //   descBullets: [
    //     // "I have maintained the highest CGPA in my batch.",
    //     // "I am serving as coordinator of The Media Club.",
    //     // "I have organised and hosted multiple events on various occasions."
    //   ]
    // },
    {
      schoolName: "Indian Institue of Information Technology Tiruchirappalli",
      logo: require("./assets/images/iiitt.png"),
      subHeader: "B. Tech. in Computer Science",
      duration: "December 2020 - April 2024",
      desc: "CGPA: 9.53",
      descBullets: [
        "I have maintained the highest CGPA in my batch.",
        "I am serving as coordinator of The Media Club.",
        "I have organised and hosted multiple events on various occasions."
      ]
    },
    {
      schoolName: "City Montessori School",
      logo: require("./assets/images/cms.jpg"),
      subHeader: "Intermediate (I.S.C.)",
      duration: "Score: 96.25%",
      desc: "Ranked topper throughout all years of Intermediate in all examinations.",
      descBullets: ["I maintained the highest scores throughout.","I won medals in the COFAS olympiad.","Won multiple cash rewards through academic challenges."]
    },
    {
      schoolName: "City Montessori School",
      logo: require("./assets/images/cms.jpg"),
      subHeader: "High School (ICSE)",
      duration: "Score: 93%",
      desc: "Ranked topper throughout all years of Intermediate in all examinations.",
      descBullets: ["Was part of the best section {Class-A}.","The main area of my concentration was my academic performance.","Played football occasionally."]    }

  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Programming <Python, Java>",
      progressPercentage: "70%"
    },
    {
      Stack: "Machine Learning Technologies <Python, Tensorflow>", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    // {
    //   Stack: "Frontend Frameworks", //Insert stack or technology you have experience in
    //   progressPercentage: "50%" //Insert relative proficiency in percentage
    // },
    {
      Stack: "Backend Frameworks",
      progressPercentage: "80%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Data Science Intern",
      company: "OPTUM HEALTH & TECHNOLOGIES",
      companylogo: require("./assets/images/optum.png"),
      date: "Jan 2024 – July 2024",
      desc: "Process Excellence Team",
      descBullets: [
        "Spearheaded the development of a compliance engine from inception, projecting potential revenue generation in the millions for the company.",
        "Automated several processes, resulting in a weekly reduction of 20 hours of manpower, enhancing team efficiency significantly.",
        "Implemented code optimizations that reduced runtime by up to 98%. Revamped an existing product’s codebase by eliminating redundancy and enhancing code readability.",
        "Authored comprehensive documentation for three existing project codebases.",
      ]
    },
    {
      role: "Software Developer Intern",
      company: "NICKELFOX TECHNOLOGIES",
      companylogo: require("./assets/images/nickelfox.png"),
      date: "June 2023 – August 2023",
      desc: "Python Back-end Developer",
      descBullets: [
        "Spearheaded a separate project involving the integration of an OPEN AI API into the backend, and enabling content generation for a client project at pre scheduled intervals.",
        "Additionally, I designed a robust and versatile backend system for ”The Grill Scrible” blogging website, prioritizing code modularity, reusability, and efficiency.",
        "Technologies- Django Rest Framework, MySql, and JWT authentication, Celery.",
      ]
    },
    {
      role: "Data Science Internship",
      company: "GlobalLogic - Hitachi Group",
      companylogo: require("./assets/images/globallogic.png"),
      date: "June 2023 – August 2023",
      desc: "PG Data Hub – MVP",
      descBullets: [
        "    Constructed a price elasticity modeling system for an E-Commerce Exchange Platform, emphasizing data pre-processing, modeling, and efficiency.",
        "Leveraged Python and Django to implement machine learning techniques for accurate price elasticity predictions.",
        "Improved platform efficiency and decision-making processes, enhancing overall performance."
      ]
    },
    {
      role: "Summer Intern ’22",
      company: "NIT Trichy",
      companylogo: require("./assets/images/nitt.png"),
      date: "July 2022 – September 2022",
      desc: "Artificial Intelligence Lab, Dept. of Computer Application ",
      descBullets: [
        "Focus of the work was to use machine learning and deep learning to enhance the growth of Urban Agriculture System.",
        "Worked on two paper under Dr. U.S. Reddy which are published in IEEE."
      ]
    },
    {
      role: " Summer Fellowship ’22 | BSSE ",
      company: "The Indian Institute of Science (IISc)",
      companylogo: require("./assets/images/iisc.jpg"),
      date: "May 2022 – June 2022",
      desc: "The Centre for BioSystems Science and Engineering (BSSE) ",
      descBullets: [
        "Worked with RACIPE: a computational tool for modeling gene regulatory circuits using randomization under Dr. Mohit Kumar Jolly.",
        "Focus of the work was to use machine learning and deep learning to study Gene Regulatory Networks."
      ]
    },
    {
      role: "LateRAISSE | LREC'22 ",
      company: "ELRA",
      companylogo: require("./assets/images/elra.jpg"),
      date: "Dec 2021 – April 2022 | Conference Paper",
      desc: "Work shop was a full day event held on June 25,2022 in Marseille, France at Le Palais du Pharo",
      descBullets: [
        "Presented my study on the region specific bias in monolingual word embeddings of various Indian languages.",
        "This was my first publication."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigWebProjects = {
  title: "WebDev Showcase",
  subtitle: "A GLIMPSE OF MY WEB DEVELOPMENT WORK",
  projects: [
    {
      image: require("./assets/images/basic-layout-demo.gif"),
      projectName: "The Art Palace",
      projectDesc: "Established a virtual art gallery for artists to showcase their work and unite events and sponsors in the art world. Implemented features for artists to register, log in, share their work, and improve their skills through onsite resources.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://live-theartpalace.pantheonsite.io/"
        },
        {
          name: "Visit Source",
          url: "https://github.com/Tiwari-Pranav/TheArtPalace"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/culinary.png"),
      projectName: "Culinary Query",
      projectDesc: "Devised the Culinary Query, an AI-Enhanced Menu Item Question Generator for training purposes, achieving a remarkable 75% improvement in response time efficiency through prompt engineering. Utilized Django REST Framework and OpenAI’s API with cron jobs.",
      footerLink: [
        {
          name: "Live At",
          url: "https://culinaryquery.pythonanywhere.com/"
        },
        {
          name: "Visit Source",
          url: "https://github.com/Tiwari-Pranav/Culinary-Query"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/Grill-Scrible.jpeg"),
      projectName: "Grill-Scrible",
      projectDesc: "Engineered the Grill Scrible, a robust and flexible backend for a Blogging Website, resulting in a 25% increase in website speed and a 40% boost in user engagement. Features authentication, pagination, and search functionality using Django Rest Framework.",
      footerLink: [
        {
          name: "Live At",
          url: "https://pranavtiwari.pythonanywhere.com/"
        },
        {
          name: "Visit Source",
          url: "https://github.com/Tiwari-Pranav/Grill-Scrible"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/index.png"),
      projectName: "Unity-Hospital-Managment-System",
      projectDesc: "A Hospital Management System is an integrated information system for managing all aspects of a hospital's appointment operations.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://github.com/Tiwari-Pranav/Unity-Hospital-Managment-System--Source-Code"
        }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};
const bigDataProjects = {
  title: "Data Science Endeavors",
  subtitle: "UNLEASHING DATA SCIENCE CREATIVITY",
  projects: [
    {
      image: require("./assets/images/traffic.jpeg"),
      projectName: "TrafficSignNet",
      projectDesc: "Implemented CNN for Traffic Sign Classification using the GTSRB dataset with 40+ classes and over 50,000 high-quality images, achieving an impressive accuracy of 98.85% and creating a robust and extensive database.",
      footerLink: [
        {
          name: "View Source",
          url: "https://github.com/Tiwari-Pranav/TrafficSignNet"
        }
      ]
    },
    {
      image: require("./assets/images/correct.webp"),
      projectName: "NLP-Powered Text Auto-Correction",
      projectDesc: "Developed an NLP-based Autocorrector in Python to enhance text input accuracy and user experience, while also showcasing the potential for further improvements with larger datasets, transformers, n-grams, and Tf-idf.",
      footerLink: [
        {
          name: "View Source",
          url: "https://github.com/Tiwari-Pranav/NLP-Powered-Text-Auto-Correction"
        }
      ]
    },
    {
      image: require("./assets/images/MovieSuggest.jpeg"),
      projectName: "MovieSuggest",
      projectDesc: "Implemented a recommendation system in Python and Pandas using collaborative and content-based filtering techniques, enabling accurate suggestions for user preferences and leveraging movie datasets.",
      footerLink: [
        {
          name: "View Source",
          url: "https://github.com/Tiwari-Pranav/MovieSuggest"
        }
      ]
    },
    {
      image: require("./assets/images/POTATO.jpg"),
      projectName: "PotatoGuard - AI-Driven Potato Disease Classifier",
      projectDesc: "Developed a state-of-the-art Potato Disease Classification Project using Convolutional Neural Networks (CNN) to accurately detect disease onset in potato plants from images, leveraging Kaggle’s ”Plant Village” dataset. Achieved an impressive accuracy rate of 98.44%, demonstrating a expertise in image processing and DL techniques.",
      footerLink: [
        {
          name: "View Source",
          url: "https://github.com/Tiwari-Pranav/PotatoGuard"
        }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Introduction to the Biology",
      subtitle:
        "John Hopkins University",
      image: require("./assets/images/johnn.png"),
      imageAlt: "John Hopkins University",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/538CGDQWGBS8"
        }
      ]
    },
    {
      title: "Machine Learning",
      subtitle:
        "Stanford",
      image: require("./assets/images/stanford.png"),
      imageAlt: "Stanford Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/GQRV9WQ6U9H6"
        }
      ]
    },

    {
      title: "Supervised Machine Learning: Regression and Classification",
      subtitle:
        "Stanford",
      image: require("./assets/images/stanford.png"),
      imageAlt: "Stanford Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/BU89URKYMDFG"
        }
      ]
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "Stanford",
      image: require("./assets/images/stanford.png"),
      imageAlt: "Stanford Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/JERZSTMB2JG3"
        }
      ]
    },
    {
      title: "Convolutional Neural Networks",
      subtitle: "Stanford",
      image: require("./assets/images/stanford.png"),
      imageAlt: "Stanford Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/TEG5QQ2SGCUZ"
        }
      ]
    },
    {
      title: "Structuring Machine Learning Projects",
      subtitle: "Stanford",
      image: require("./assets/images/stanford.png"),
      imageAlt: "Stanford Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/CS29L8HX7S33"
        }
      ]
    },
    {
      title: "Build REST APIs with Django REST Framework and Python",
      subtitle: "Udemy",
      image: require("./assets/images/udemy.png"),
      imageAlt: "Udemy Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://udemy-certificate.s3.amazonaws.com/pdf/UC-397aeaa4-c408-408d-aff7-354e4afab682.pdf"
        }
      ]
    },
    {
      title: "Deep Learning",
      subtitle: "NPTEL",
      image: require("./assets/images/nptel.png"),
      imageAlt: "NPTEL Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/1wbGFSWcjhQ7zcB29_iw60hmShZwlLMOC/view?usp=drive_link"
        }
      ]
    },
    {
      title: "Data Analytics with Python",
      subtitle: "NPTEL",
      image: require("./assets/images/nptel.png"),
      imageAlt: "NPTEL Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/1-lbxRA40Q5oVhSDeG0kWOb7YhXpTaWe4/view?usp=drive_link"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://pranav-tiwari.medium.com/unraveling-the-price-elasticity-puzzle-decoding-consumer-behavior-for-optimal-pricing-strategies-77b1e0ee6739",
      title: "Unraveling the Price Elasticity Puzzle: Decoding Consumer Behavior for Optimal Pricing Strategies",
      description:
        "This powerful concept unveils the dynamic relationship between a product’s price and the corresponding fluctuations in its demand."
    },
    {
      url: "https://pranav-tiwari.medium.com/navigating-price-elastic-modelling-part-1-a-journey-of-data-cleaning-and-insights-9ce9ebaec46d",
      title: "Navigating Price Elastic Modelling (Part-1): A Journey of Data Cleaning and Insights",
      description:
        "Exploring the intriguing realm of price elasticity and its real-world implications. Join me as I unravel the intricacies of data cleaning in this blog."
    },
    {
      url: "https://pranav-tiwari.medium.com/navigating-price-elastic-modelling-part-2-exploratory-data-analysis-34eb76cd2874",
      title: "Navigating Price Elastic Modelling (Part-2): Exploratory Data Analysis",
      description:
        "Exploring the intriguing realm of price elasticity and its real-world implications. Join me as we dive into the depths of data visualization to unravel the secrets hidden within the dataset."
    },
    {
      url: "https://pranav-tiwari.medium.com/navigating-price-elastic-modelling-part-3-building-the-price-elasticity-model-68ef362eb33a",
      title: "Navigating Price Elastic Modelling (Part-3): Building the Price Elasticity Model",
      description:
        "Exploring the intriguing realm of price elasticity and its real-world implications. Join us as we dive into the world of statistical analysis and unleash the power of data-driven pricing strategies."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "AI and ML Demystified: A Comprehensive Introduction",
      subtitle: "IIIT Tiruchirappalli December-2022",
      slides_url: "https://drive.google.com/file/d/1DkU_-PtFeV696pvX9E3hXodC7b1yOuB7/view?usp=sharing",
      // event_url: "https://www.facebook.com/events/2339906106275053/"
    },
    {
      title: "Check yourself before you wreck yourself: Vertical Farming",
      subtitle: "IIIT Tiruchirappalli February-2022",
      slides_url: "https://drive.google.com/file/d/1bNbCz1wBoQeaicPyqo1OwUKy61DvOsmq/view?usp=sharing",
      // event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
    number: "+91-9621190362",
    email_address: "pranavtiwari548@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "SirPranavTiwari", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigWebProjects,
  bigDataProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
